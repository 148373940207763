import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy on the use of anti-virus systems.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Recommended processes to prevent virus problems:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Always run the Corporate standard, supported anti-virus software is
available from the corporate download site. Download and run the
current version; download and install anti-virus software updates as
they become available.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`NEVER open any files or macros attached to an email from an unknown,
suspicious or untrustworthy source. Delete these attachments
immediately, then “double delete” them by emptying your Trash.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Delete spam, chain, and other junk email without forwarding, in with
Data Migrators’ `}<em parentName="p">{`Acceptable Use Policy`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Never download files from unknown or suspicious sources.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Avoid direct disk sharing with read/write access unless there is
absolutely a business requirement to do so.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Always scan a floppy diskette from an unknown source for viruses
before using it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Back-up critical data and system configurations on a regular basis
and store the data in a safe place.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If lab testing conflicts with anti-virus software, run the
anti-virus utility to ensure a clean machine, disable the software,
then run the lab test. After the lab test, enable the anti-virus
software. When the anti-virus software is disabled, do not run any
applications that could transfer a virus, e.g., email or file
sharing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New viruses are discovered almost every day. Periodically check the
`}<em parentName="p">{`Lab Anti-Virus Policy`}</em>{` and this Recommended Processes list for
updates.`}</p>
      </li>
    </ul>
    <h2>{`Purpose`}</h2>
    <h2>{`Scope`}</h2>
    <h2>{`Policy`}</h2>
    <h2>{`Policy Compliance`}</h2>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      